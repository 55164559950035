const ProjectClaimsService = class ProjectClaimsService {
  constructor ($http, Configuration, ModalService, ProjectService, UserService) {
    'ngInject'

    this.$http = $http
    this.Configuration = Configuration
    this.ModalService = ModalService
    this.UserService = UserService
    this.ProjectService = ProjectService
  }

  init () {
    console.log('ProjectClaimsService > init')
  }

  get userClaimCounts () {
    return {
      available: this.UserService.user?.availableClaimCount,
      current: this.UserService.user?.currentClaimCount,
      max: this.UserService.user?.maxClaimCount
    }
  }

  get projectClaimCounts () {
    return {
      available: this.ProjectService.project?.availableClaimCount,
      current: this.ProjectService.project?.currentClaimCount,
      max: this.ProjectService.project?.maxClaimCount
    }
  }
}
export default ProjectClaimsService
