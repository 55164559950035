// modules
import AuthModule from './auth'
import UIModule from './ui/ui.module'
import DevModule from './dev/dev.module'
import ErrorsModule from './errors'

// common
import PrivacyNoticeComponent from './components/privacy-notice'
import ServerErrorModule from './components/server-error'
import InfoPopoverModule from './components/info-popover'
import TextDecorationModule from './components/text-decoration'

// services
import UtilService from './services/util.service'
import NavigationService from './services/navigation.service'
import StorageService from './services/storage.service'
import UserService from './services/user.service'
import ProjectService from './services/project.service'
import IntercomService from './services/intercom.service'
import SoundService from './services/sound.service'
import FeedbackService from './services/feedback.service'
import FeeService from './services/fee.service'
import LoggingService from './services/logging.service'
import PusherService from './services/pusher.service'
import EventBusService from './services/event-bus.service'
import ProjectRemindersService from './services/project-reminders.service'
import SleekService from './services/sleek.service'
import ExperimentService from './services/experiment.service'
import ProjectClaimsService from './services/project-claims.service'


// directives
import ngcloakDirective from './directives/ngcloak.directive'
import ngformDirective from './directives/ngform.directive'
import characterCountDirective from './directives/character-count.directive'
import passwordRevealDirective from './directives/password-reveal.directive'
import checkRequiredDirective from './directives/checkbox-required.directive'
import stickyDirective from './directives/sticky.directive'
import cdblBindCompileDirective from './directives/bind-compile.directive'
import unsignedOnlyDirective from './directives/unsigned-only.directive'
import ctrlEnterDirective from './directives/ctrl-enter.directive'
import cdblTrackDirective from './directives/track.directive'
import onLastRenderDirective from './directives/on-last-render.directive'
import persistentScrollDirective from './directives/persistent-scroll.directive'
import fileSelectDirective from './directives/file-select.directive'

// import clickOutsideDirective from 'directives/click-outside.directive'
// import backlistValidator from 'directives/backlist-validator.directive'

// filters
import excludeFromArrayFilter from './filters/exclude-from-array.filter'
import underscoreStripFilter from './filters/underscore-strip.filter'
import toUserTimezoneFilter from './filters/to-user-timezone.filter'
import dateFormatFilter from './filters/date-format.filter'
import currencyFormatFilter from './filters/currency-format.filter'
import budgetDisplayFilter from './filters/budget-display.filter'
import linksNewTabFilter from './filters/links-new-tab.filter'
import toArray from './filters/toArray.filter'
import offsetFilter from './filters/offset.filter'
import markedFilter from './filters/marked.filter'
import trustAsResourceUrlFilter from './filters/trustAsResourceUrl.filter'
import ProjectUrgencyFormatFilter from './filters/project-urgency-format.filter'
import ProjectStateFormatFilter from './filters/project-state-format.filter'
import AttachmentNameFormatFilter from './filters/attachment-name-format.filter'
import TruncateFormatFilter from './filters/truncate-format.filter'
import DateDifferenceFilter from './filters/date-difference.filter'
import DateFromRelativeFilter from './filters/date-from-relative.filter'
import PercentageFormatFilter from './filters/percentage-format.filter'
import ProjectVersionFilter from './filters/project-version.filter'
import BudgetRangeFormatFilter from './filters/budget-range-format.filter'
import MapNullToZero from './filters/map-null-to-zero.filter'
import UrlProtocolPrefixerFilter from './filters/url-protocol-prefixer.filter'
import ProjectClientTypeFilter from './filters/project-client-type.filter'
import SentencecaseFilter from './filters/sentencecase.filter'
import MachinenameToTextFilter from './filters/machinename-to-text.filter'
import pluralizeFilter from './filters/pluralize.filter'




const CommonModule = angular.module('app.common', [
  AuthModule,
  UIModule,
  DevModule,
  ErrorsModule,
  ServerErrorModule,
  InfoPopoverModule,
  TextDecorationModule
])
  .service('UtilService', UtilService)
  .service('NavigationService', NavigationService)
  .service('StorageService', StorageService)
  .service('UserService', UserService)
  .service('ProjectService', ProjectService)
  .service('IntercomService', IntercomService)
  .service('SoundService', SoundService)
  .service('FeedbackService', FeedbackService)
  .service('FeeService', FeeService)
  .service('LoggingService', LoggingService)
  .service('PusherService', PusherService)
  .service('EventBusService', EventBusService)
  .service('ProjectRemindersService', ProjectRemindersService)
  .service('SleekService', SleekService)
  .service('ExperimentService', ExperimentService)
  .service('ProjectClaimsService', ProjectClaimsService)




  .directive('ngCloak', ngcloakDirective)
  .directive('form', ngformDirective)
  .directive('ngForm', ngformDirective)
  .directive('characterCount', characterCountDirective)
  .directive('passwordReveal', passwordRevealDirective)
  .directive('checkRequired', checkRequiredDirective)
  .directive('cdblUnsignedOnly', unsignedOnlyDirective)
  .directive('cdblCtrlEnter', ctrlEnterDirective)
  .directive('cdblSticky', stickyDirective)
  .directive('cdblBindCompile', cdblBindCompileDirective)
  .directive('cdblTrack', cdblTrackDirective)
  .directive('onLastRender', onLastRenderDirective)
  .directive('persistentScroll', persistentScrollDirective)
  .directive('fileSelect', fileSelectDirective)

// .directive('cdblClickOutside', clickOutsideDirective)
// .directive('backlist', backlistValidator)

  .filter('excludeFromArray', excludeFromArrayFilter)
  .filter('underscoreStrip', underscoreStripFilter)
  .filter('toUserTimezone', toUserTimezoneFilter)
  .filter('dateFormat', dateFormatFilter)
  .filter('currencyFormat', currencyFormatFilter)
  .filter('budgetDisplay', budgetDisplayFilter)
  .filter('linksNewTab', linksNewTabFilter)
  .filter('toArray', toArray)
  .filter('offset', offsetFilter)
  .filter('cdblMarked', markedFilter)
  .filter('trustAsResourceUrl', trustAsResourceUrlFilter)
  .filter('projectUrgencyFormat', ProjectUrgencyFormatFilter)
  .filter('projectStateFormat', ProjectStateFormatFilter)
  .filter('attachmentNameFormat', AttachmentNameFormatFilter)
  .filter('truncateFormat', TruncateFormatFilter)
  .filter('dateDifference', DateDifferenceFilter)
  .filter('dateFromRelative', DateFromRelativeFilter)
  .filter('percentageFormat', PercentageFormatFilter)
  .filter('projectVersion', ProjectVersionFilter)
  .filter('budgetRangeFormat', BudgetRangeFormatFilter)
  .filter('mapNullToZero', MapNullToZero)
  .filter('urlProtocolPrefixer', UrlProtocolPrefixerFilter)
  .filter('projectClientType', ProjectClientTypeFilter)
  .filter('sentencecase', SentencecaseFilter)
  .filter('machinenameToText', MachinenameToTextFilter)
  .filter('pluralize', pluralizeFilter)


  .value('EventEmitter', payload => ({ $event: payload })) // helper event wrapper for '&' bindings

  // common components
  .component('cdblPrivacyNotice', PrivacyNoticeComponent)

  .name

export default CommonModule

