// TODO: this service file will once legacy Sockets service is refactored become main point of pusher integration
// - move subscripton to dynamic channels and event binding
const PusherService = class PusherService {
  constructor ($timeout, AuthTokenService, Sockets, DataInterceptor) {
    'ngInject'
    this.$timeout = $timeout
    this.AuthTokenService = AuthTokenService
    this.Sockets = Sockets // TODO: refactor Sockets service into this Pusher service
    this.DataInterceptor = DataInterceptor
    this.presenceChannel = null
    this.subscriptions = {} // Private map to track subscribed channels - this is separate from SocketsSrv and it is meant to decouple subscribing to pusher channels from SocketSrv chaos
  }


  init (userId, userRole, authToken) {
    // Sockets expects user object (it only uses id and role) and auth token for intitialization
    this.Sockets.init({
      id: userId,
      role: userRole
    }, authToken)

    this.initPresenceChannel()
  }

  get pusher () {
    return this.Sockets.getPusher()
  }


  get isInitialized () {
    return this.Sockets.isInitialized()
  }

  updateAuthToken (newToken) {
    if (!newToken) {
      newToken = this.AuthTokenService.get()
    }

    if (newToken && this.isInitialized) {
      this.Sockets.updateToken(newToken)
    }
  }

  unsubscribeAll () {
    this.Sockets.unsubscribeAll()
  }

  initPresenceChannel () {
    this.presenceChannel = this.Sockets.getChannel('presence-online') // subscribed in legacy Sockets init method

    if (this.presenceChannel) {
      const vm = this
      this.presenceChannel.bind('pusher:subscription_succeeded', members => {
        vm.$timeout(() => {})
      })

      this.presenceChannel.bind('pusher:member_added', member => {
        vm.$timeout(() => {})
      })

      this.presenceChannel.bind('pusher:member_removed', member => {
        vm.$timeout(() => {})
      })
    }
  }

  isUserOnline (userId) {
    if (this.presenceChannel && this.presenceChannel.members) {
      const presenceUser = this.presenceChannel.members.get(userId)
      if (presenceUser && presenceUser.id === userId) {
        return true
      }
    }

    return false
  }

  subscribe (channelName) {
    if (!this.pusher) {
      throw new Error('Pusher is not initialized')
    }
    if (!this.subscriptions[channelName]) {
      this.subscriptions[channelName] = this.pusher.subscribe(channelName)
    }
    return this.subscriptions[channelName]
  }

  unsubscribe (channelName) {
    if (this.subscriptions[channelName]) {
      this.pusher.unsubscribe(channelName)
      delete this.subscriptions[channelName]
    }
  }

  bindEvent (channelName, eventName, callback) {
    const channel = this.subscribe(channelName)
    channel.bind(eventName, callback)
  }

  unbindEvent (channelName, callback) {
    if (this.subscriptions[channelName]) {
      this.subscriptions[channelName].unbind(callback)
    }
  }

  bindGlobal (channelName, callback) {
    const channel = this.subscribe(channelName)
    // Use a wildcard event binding for dynamic events
    channel.bind_global((eventName, data) => {
      callback(channelName, eventName, this.DataInterceptor.convertCase(data, 'toCamelCase')) // Pass event name and data to the callback
    })
  }
  unbindGlobal (channelName) {
    const channel = this.subscribe(channelName)
    channel.unbind_global()
  }

  unbindAll (channelName) {
    const channel = this.subscribe(channelName)
    channel.unbind_all()
    console.log('🚀 ~ PusherService ~ unbindAll ~ unbindAll:', channelName)
  }

  cleanup () {
    for (const channelName in this.subscriptions) {
      this.unsubscribe(channelName)
    }
  }
}
export default PusherService
