import templateUrl from './sidebar-widget.html'
import './sidebar-widget.scss'

const ProjectClaimsSidebarWidgetComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectClaimsSidebarWidgetComponent {
    constructor (PusherService, ProjectClaimsService, ProjectService, UserService, $timeout) {
      'ngInject'
      this._identify = 'ProjectClaimsSidebarWidgetComponent'
      this.PusherService = PusherService
      this.isSubscribed = false
      this.ProjectClaimsService = ProjectClaimsService
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.$timeout = $timeout
    }

    $onInit () {
      this.ProjectClaimsService.init() // take care of any prep work if needed and execute it each time this component is created

      const currentUserId = this.UserService.user.id
      const projectId = this.ProjectService.project.id

      if (!projectId) {
        throw new Error('Claim project id is not known, use component only in areas that ProjectService.project exists')
      }

      // Note: Due too sidebar widget component being removed after the service already registers route change (and looses current project) store names of channels so they can be unsubscribed when component is destroyed
      this.channelNames = {
        claimers: `project-${projectId}-claimers`,
        observers: `project-${projectId}-observers`,
        expert: `expert-${currentUserId}`
      }
    }

    $onDestroy () {
      console.log('🚀 ~ ProjectClaimsSidebarWidgetComponent ~ $onDestroy')
      this.unsubscribe()
    }

    handleEvents (channelName, eventName, data) {
      // NOTE: this is global handler for all events for purpose of development and debugging,
      // use specific handles to separate different events
      console.log('🚀 ~ ProjectClaimsSidebarWidgetComponent handleEvents', channelName, eventName, data)
      console.log(data.id)
      if (eventName === 'project-engagement-claimed' && this.ProjectService.project?.id === data.id) {
        Object.assign(this.ProjectService.project, data)
        console.log('🚀 ~ ProjectClaimsSidebarWidgetComponent handleEvents ~ update project', this.ProjectService.project)
      }
      this.$timeout()
    }


    subscribe () {
      // NOTE: bind to specific events with their specific handlers,
      // here is just an example to bindGlobally for capturing all events and debug

      // Subscribe and bind events via PusherService
      this.PusherService.bindGlobal(this.channelNames.claimers, (channelName, eventName, data) => {
        this.handleEvents(channelName, eventName, data)
      })
      this.PusherService.bindGlobal(this.channelNames.observers, (channelName, eventName, data) => {
        this.handleEvents(channelName, eventName, data)
      })
      this.PusherService.bindGlobal(this.channelNames.expert, (channelName, eventName, data) => {
        this.handleEvents(channelName, eventName, data)
      })


      this.isSubscribed = true
    }

    unsubscribe () {
      // NOTE: unbind form specific events with their specific handlers or from channels,
      // here is just an example to unbindGlobally, use unbindAll or unbind - see PusherService for more info

      const channels = [this.channelNames.claimers, this.channelNames.observers, this.channelNames.expert]
      channels.forEach(channelName => {
        this.PusherService.unbindGlobal(channelName)
      })

      channels.forEach(channelName => {
        this.PusherService.unsubscribe(channelName)
      })

      console.log('🚀 ~ ProjectClaimsSidebarWidgetComponent ~ unsubscribe')
      this.isSubscribed = false
    }
  }
}

export default ProjectClaimsSidebarWidgetComponent
